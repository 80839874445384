




































































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { items } from '@/data/navigation';
import { INavigationItem } from '@/data/navigation.types';

export default Vue.extend({
  data() {
    return {
      background: true,
      items: items,
      opacity: 0.4
    };
  },

  computed: {
    ...mapGetters({
      getThemeMode: 'getThemeMode',
      user: 'auth/getUser'
    }),
    bg(): string | undefined {
      return this.background
        ? 'https://images.pexels.com/photos/1687678/pexels-photo-1687678.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260'
        : undefined;
    },

    computedItems(): INavigationItem[] {
      return this.items.map(this.mapItem);
    }
  },

  mounted() {
    if (!this.user) {
      this.fetchUser();
    }
  },

  methods: {
    ...mapActions({
      fetchUser: 'auth/fetchUser'
    }),

    mapItem(item: INavigationItem): INavigationItem {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title
      };
    }
  }
});
